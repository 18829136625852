/* eslint camelcase: "off", import/no-named-as-default: "off", import/no-named-as-default-member: "off" */
/* eslint import/extensions: "off", import/no-unresolved: "off"  */
import { app } from 'shop/assets/js/bootstrap';

import './styles/index.scss';


// => Importer manuellement les controllers du plugin news
import OfferSegmentProduct from './controllers/offer-segment-product_controller';
import OfferSegment from './controllers/offer-segment_controller';
import OfferWithSegment from './controllers/offer-with-segment_controller';
import OffersAside_controller from './controllers/offers-aside_controller';
import ProductModal_controller from './controllers/product-modal_controller';

app.register('black-sylius-offer-plugin--offer-segment-product', OfferSegmentProduct);
app.register('black-sylius-offer-plugin--offer-segment', OfferSegment);
app.register('black-sylius-offer-plugin--offer-with-segment', OfferWithSegment);
app.register('black-sylius-offer-plugin--offers-aside', OffersAside_controller);
app.register('black-sylius-offer-plugin--product-modal', ProductModal_controller);
