import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

class default_1 extends Controller {
  connect() {
    // Initialisation de l'observer pour l'intersection
    const [observeIntersection, unobserveIntersection] = useIntersection(
      this,
      this.optionsValue || {},
    );
    this.observeIntersection = observeIntersection;
    this.unobserve = () => unobserveIntersection();

    // Observer l'événement beforeprint
    window.addEventListener('beforeprint', () => {
      this.loadImageManually();
    });
  }

  appear(entry) {
    this._loadImage(); // Fonction interne pour charger l'image
    this.unobserve();
  }

  disconnect() {
    this.unobserve();
    super.disconnect();
  }

  loadImageManually() {
    // Cette méthode force le chargement de l'image même si elle n'est pas dans le viewport
    if (!this.element.classList.contains('lazy-image-loaded')) {
      this._loadImage();
    }
  }

  _loadImage() {
    const hd = new Image();
    const srcsetString = this._calculateSrcsetString();
    this.element.src = this.srcValue;

    hd.addEventListener('load', () => {
      this.element.src = this.srcValue;
      if (srcsetString) {
        this.element.srcset = srcsetString;
      }
      this.element.classList.add('lazy-image-loaded');
      this._dispatchEvent('lazy-image:ready', { image: hd });
    });

    hd.src = this.srcValue;
    if (srcsetString) {
      hd.srcset = srcsetString;
    }
    this._dispatchEvent('lazy-image:connect', { image: hd });
  }

  _calculateSrcsetString() {
    if (!this.hasSrcsetValue) {
      return '';
    }
    const sets = Object.keys(this.srcsetValue)
      .map(size => `${this.srcsetValue[size]} ${size}`);
    return sets.join(', ')
      .trimEnd();
  }

  _dispatchEvent(name, payload) {
    this.element.dispatchEvent(new CustomEvent(name, { detail: payload }));
  }
}

default_1.values = {
  src: String,
  options: Object,
  srcset: Object,
};

export { default_1 as default };
