import { Controller } from "@hotwired/stimulus";
import { boundMethod } from "autobind-decorator";
import gsap from "gsap";
import { useMutation } from "stimulus-use";

export default class extends Controller {
  connect() {
    this.menuLogo = this.element.querySelector(".menu-logo");
    this.menuLogoImg = this.element.querySelector("img");

    this.miniMenuLogo = this.element.querySelector(".mini-menu-logo");
    this.miniMenuLogoImg = this.miniMenuLogo.querySelector("img");

    window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.handleScroll();

    setTimeout(() => {
      useMutation(this, {
        element: document.body,
        attributes: true,
        attributeFilter: ["class"],
      });
    }, 1000);
  }
  mutate(entries) {
    this.handleScroll();
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  @boundMethod
  handleScroll() {
    window.scrollY > 20 ? this.scrollDownAnims() : this.scrollUpAnims();
  }

  @boundMethod
  scrollDownAnims() {
    gsap.to(this.element, {
      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
      height: window.innerWidth > 768 ? "75px" : "110px",
    });
    gsap.to(this.menuLogo, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.4,
    });
    gsap.to(this.menuLogoImg, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.4,
    });

    gsap.to(this.miniMenuLogo, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.4,
    });
    gsap.to(this.miniMenuLogoImg, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.4,
    });
  }

  @boundMethod
  scrollUpAnims() {
    gsap.to(this.element, {
      boxShadow: "0px 0px 0px rgba(0,0,0,0)",
      height: "180px",
      // height: window.innerWidth > 768 ? '150px' : '95px'
    });
    gsap.to(this.menuLogo, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.4,
    });
    gsap.to(this.menuLogoImg, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.4,
    });

    gsap.to(this.miniMenuLogo, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.4,
    });
    gsap.to(this.miniMenuLogoImg, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.4,
    });
  }
}
