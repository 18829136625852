import { Controller } from '@hotwired/stimulus';
import axios from 'axios'

export default class extends Controller {

  initialize () {
    console.log('%c   ////    ','background-color:#B36D35');

    this.onClick = this.onClick.bind(this)
    this.redirectUrl = this.element.getAttribute('data-js-remove-from-cart-redirect-url')
    this.csrfToken = this.element.getAttribute('data-js-remove-from-cart-csrf-token')
    this.url = this.element.getAttribute('data-js-remove-from-cart-api-url')
    this.element.addEventListener('click', this.onClick)
  }

  onClick () {
    axios.delete(this.url, { data: { _csrf_token: this.csrfToken } }).then(() => { window.location.replace(this.redirectUrl) })
  }

  connect () {

  }

  disconnect () {
    this.element.removeEventListener('click', this.onClick)
  }

}
