import { IntersectionController } from 'stimulus-use';
import gsap from 'gsap';

export default class extends IntersectionController {
  static values = {
    duration: Number,
    delay: Number,
    threshold: Number,
  };

  options = {
    threshold: 0.2, // default
  };

  initialize () {
    this.options.threshold = this.thresholdValue;
  }

  appear (entry) {
    if(window.appSwup && window.appSwup.skipAnimation ){
      return;
    }
    this.unobserve();
    gsap.to(
      this.element,
      {
        ease: 'power2.in',
        opacity: 1,
        delay: this.delayValue || 0,
        duration: this.durationValue || 0.5,
      },
    );
  }

}
