import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default class extends Controller {
  static values = {
    threshold: Number,
    delay: Number,
    markers: Boolean,
  };

  initialize () {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(
      this.element,
      {
        ease: 'power2.in',
        duration: this.durationValue || 0.5,
        delay: this.delayValue || 0,
        opacity: 1,
        scrollTrigger:{
          once:true,
          markers: this.markersValue || false ,
          start:`top ${this.thresholdValue || 95}%`,
          trigger: this.element,
        }
      },
    );
  }

}
