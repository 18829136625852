import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

export default class ShowPasswordController extends Controller {
  static targets = ["show", "hide"];
  connect() {
    this.hide();
    this.input = this.element.querySelector("input");
  }
  toggle() {
    if (this.input.getAttribute("type") === "password") {
      this.show();
      return this.input.setAttribute("type", "text");
    }
    this.hide();
    this.input.setAttribute("type", "password");
  }

  hide() {
    gsap.to(this.showTarget, {opacity: 1, pointerEvents: "auto", duration: 0.2,});
    gsap.to(this.hideTarget, {opacity: 0, pointerEvents: "none", duration: 0.2,});
  }
  show() {
    gsap.to(this.hideTarget, {opacity: 1, pointerEvents: "auto", duration: 0.2,});
    gsap.to(this.showTarget, {opacity: 0, pointerEvents: "none", duration: 0.2,});
  }
}
