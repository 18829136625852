/* eslint camelcase: "off", import/no-named-as-default: "off", import/no-named-as-default-member: "off" */
/* eslint import/extensions: "off", import/no-unresolved: "off"  */
import { app } from 'shop/assets/js/bootstrap';

import './styles/index.scss';

// => Importer manuellement les controllers du plugin richeditor
import anchorLinkMenu_controller from './controllers/anchor-link-menu_controller';
import anchorLink_controller from './controllers/anchor-link_controller';
import popup_controller from './controllers/popup_controller';
import promoteSlider_controller from './controllers/promote-slider_controller';
import sliderTextImage_controller from './controllers/slider-text-image_controller';

app.register('monsieur-biz-sylius-editor-plugin--anchor-link-menu', anchorLinkMenu_controller);
app.register('monsieur-biz-sylius-editor-plugin--anchor-link', anchorLink_controller);
app.register('monsieur-biz-sylius-editor-plugin--popup', popup_controller);
app.register('monsieur-biz-sylius-editor-plugin--promote-slider', promoteSlider_controller);
app.register('monsieur-biz-sylius-editor-plugin--slider-text-image', sliderTextImage_controller);
