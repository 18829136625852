import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.dispatchEvent(new Event('change'));
  }

  onChange(event) {
    event.preventDefault();
    const toggle = event.currentTarget;
    const targetElement = document.querySelector(`${this.element.dataset.targetSelect}`);
    if (!targetElement) return;
    if (toggle.checked === true) {
      targetElement.classList.remove('hidden');
    } else {
      targetElement.classList.add('hidden');
    }
  }
}
