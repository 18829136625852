import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import gsap from 'gsap';

export default class extends Controller {

  static values= {
    autoplay: Boolean,
    delay: Number,
  };

  connect () {
    this.slider = new Swiper(
      this.element,
      {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed:1000,

        autoplay: {
          enabled: this.autoplayValue || false,
          delay: this.delayValue || 2000,
          disableOnInteraction: false,
        },

        keyboard: true,
        navigation: {
          nextEl: this.element.querySelector(".swiper-button-next"),
          prevEl: this.element.querySelector(".swiper-button-prev"),
        },
        pagination: {
          el: this.element.querySelector(".swiper-pagination"),
          clickable: true,
        },
      },
    );
  }

  disconnect () {
    this.slider.destroy();
  }
}
