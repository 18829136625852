import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import { gsap } from 'gsap';

export default class extends Controller {

  initialize() {

  }

  connect() {
    useIntersection(this, { threshold: 0});

  }

  appear(entry) {
    this.element.classList.add('in-view');
    gsap.to(this.element, {opacity: 1, duration: 0.3, ease: 'power2.in', delay: this.element.dataset.delay || 0 });
  }

  disappear(entry) {
    this.element.classList.remove('in-view');
  }

  disconnect(){
   // console.log('product items discconnect');
  }

}
