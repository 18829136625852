import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

export default class extends Controller {
  static targets = ["label", "dropdown"];
  connect() {
    this.tl = gsap.timeline();
  }
  onClickItem(e) {
    const currentTarget = e.currentTarget;
    if(!currentTarget) return;
    if(currentTarget.innerText){
      this.dropdownTarget.querySelectorAll('[data-action*="click->daisy--dropdownEnhanced#onClickItem"]').forEach(item => {
        if(item === currentTarget) item.setAttribute('selected',  true)
        else{
          item.hasAttribute('selected') && item.removeAttribute('selected') ;
        }
      })
      this.tl.to(this.labelTarget, {
        opacity: 0,
        duration: 0.3,
      });

      if(currentTarget.querySelector('strong')) {
        this.labelTarget.innerHTML = currentTarget.innerHTML.replace('</strong>,','</strong><br />');
      }
      else{
        this.labelTarget.innerText = currentTarget.innerText;
      }

      this.tl.to(this.labelTarget, {
        opacity: 1,
      });
    }
    document.activeElement.blur();
  }

  disconnect() {
    this.tl.kill();
  }
}
