import { Controller } from "@hotwired/stimulus";
import { boundMethod } from "autobind-decorator";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import gsap from "gsap";

export default class extends Controller {
  static values = {
    autoplay: Boolean,
    delay: Number,
  };

  connect() {
    // console.log("HomeSliderController#connect");
    this.swiper = new Swiper(this.element, {
      direction: "horizontal",
      loop: this.element.querySelectorAll('.swiper-slide').length > 1,

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        enabled: this.autoplayValue || false,
        delay: this.delayValue || 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      speed: 2500,
      // allowTouchMove: false,
      allowTouchMove: true,
      grabCursor: false,
      on: {
        slideChangeTransitionStart: this.onSlideChangeTransitionStart,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });
    setTimeout(() => {
      this.animateText();
    }, 1500);
  }

  disconnect() {
    this.swiper.off("slideChangeTransitionStart", this.onSlideChangeTransitionStart);
    this.swiper.destroy();
  }

  @boundMethod
  onSlideChangeTransitionStart() {
    this.animateText();
  }

  @boundMethod
  animateText() {
    const swiperTexts =  this.element.querySelectorAll(".swiper-text");
    if(swiperTexts.length === 0) return;
    gsap.to(this.element.querySelectorAll(".swiper-text"), {
      opacity: 0,
      x: 45,
      duration: 1,
      delay: 0,
      ease: "power2.out",
    });
    gsap.fromTo(this.element.querySelector(".swiper-slide-active .swiper-text"), {
      opacity: 0,
      x: -30,
      duration: 0.75,
    },{
      opacity: 1,
      x: 0,
      duration: 1.2,
      delay: 0.5,
      ease: "power2.out",
    });

  }
}
