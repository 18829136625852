import { Controller } from '@hotwired/stimulus';
import { boundMethod } from 'autobind-decorator';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.onClick);
    this.pattern = new RegExp(/^#.{1,}$/);
    this.topOffset = 88;
  }
  disconnect() {
    this.element.removeEventListener('click', this.onClick);
  }

  @boundMethod
  onClick(e) {
    e.preventDefault();
    const targetElement = document.querySelector(this.element.getAttribute('href'));

    if (targetElement) {
      window.scrollTo({
        top: targetElement.getBoundingClientRect().top + window.scrollY - this.topOffset,
        behavior: 'smooth',
      });
    }
  }
}
