import { IntersectionController } from 'stimulus-use';
import gsap from 'gsap';
import { boundMethod } from 'autobind-decorator';
import {CART_EVENTS} from "../../events/cart";

export default class extends IntersectionController {
  static targets = [ "productDetails","productBuy","productBuyListElt" ]

  connect() {
    this.isBuyDisplay = false;
    document.addEventListener(CART_EVENTS.additem_completed,this.addToCartCompleted)

  }

  @boundMethod
  addToCartCompleted(evt){

    if(!this.isBuyDisplay) {
      return;
    }
    this.toggleDisplay(true);

  }

  disconnect() {
    document.removeEventListener(CART_EVENTS.additem_completed,this.addToCartCompleted);

  }


  onCloseBuyClick(event){
    this.hideBuy();
  }

  onBuyClick() {
    this.isBuyDisplay ? this.hideBuy() : this.displayBuy();

  }

  hideBuy(){
    this.toggleDisplay(false);

  }

  displayBuy(){
    this.toggleDisplay(true);

  }

  @boundMethod
  async toggleDisplay(isBuyDisplay) {
    gsap.to(this.productDetailsTarget,{duration:.5,opacity:isBuyDisplay ? 0 : 1})

    if(isBuyDisplay) {
      const response = await fetch(this.element.dataset.urlAddToCartList, {
        method: 'GET',

      });
      this.productBuyListEltTarget.innerHTML = await response.text();
    }

    this.productDetailsTarget.style.pointerEvents = isBuyDisplay ? `none` : 'auto';
    this.productBuyTarget.style.pointerEvents = isBuyDisplay ? 'auto' : 'none';
    gsap.to(this.productBuyTarget,{duration:.5,opacity:isBuyDisplay ? 1 : 0})
    this.isBuyDisplay = isBuyDisplay;

  }

  appear (entry) {
    this.unobserve();
    gsap.to(this.element,{duration:.5,opacity: 1 });

  }
}
