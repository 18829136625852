import { IntersectionController } from "stimulus-use";
import gsap from "gsap";

export default class extends IntersectionController {
  static values = {
    from: String,
    duration: Number,
    delay: Number,
  };
  connect() {
    gsap.set(this.element, {
      opacity: 0,
    });
    this.animPlayed = false;
  }

  animateClipPolygon() {
    gsap.to(this.element, {
      opacity: 1,
      duration: 0.5,
    });
    gsap.to(this.element, {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: this.durationValue || 2,
      delay: this.delayValue || 0,
      ease: "power2.out",
    });
  }
  animateClipCircle() {
    gsap.to(this.element, {
      opacity: 1,
      duration: 0.5,
    });
    gsap.to(this.element, {
      clipPath: "circle(100%)",
      duration: this.durationValue || 3,
      delay: this.delayValue || 0,
      ease: "power2.out",
    });
  }

  setClipPath() {
    switch (this.fromValue) {
      case "left":
        this.element.style.clipPath = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";
        this.animateClipPolygon();
        break;
      case "right":
        this.element.style.clipPath =
          "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)";
        this.animateClipPolygon();
        break;
      case "top":
        this.element.style.clipPath = "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)";
        this.animateClipPolygon();
        break;
      case "bottom":
        this.element.style.clipPath =
          "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)";
        this.animateClipPolygon();
        break;
      case "center":
        this.element.style.clipPath =
          "polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)";
        this.animateClipPolygon();
        break;
      case "circle":
        this.element.style.clipPath = "circle(0%)";
        this.animateClipCircle();
        break;
      default:
        this.element.style.clipPath = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";
        this.animateClipPolygon();
    }
  }

  appear() {
    if (!this.animPlayed) {
      this.setClipPath();
    }
  }
  disappear() {
    this.animPlayed = true;
  }
}
