import { Controller } from '@hotwired/stimulus';
import AddToCart from '../../core/add-to-cart';
import QuantitySelector from '../../core/quantity-selector';
import {boundMethod} from 'autobind-decorator';

export default class extends Controller {

  @boundMethod
  getSelectedProductVariantCode () {
    return this.selectProductVariant.value;
  }

  initInputQuantity () {
    this.inputQuantity = this.formAddToCart.querySelector(`[name='sylius_add_to_cart[cartItem][quantity]']`);
    this.inputQuantity.setAttribute('inputmode', 'numeric');
    this.quantitySelector = new QuantitySelector(this.inputQuantity);
    this.quantitySelector && this.quantitySelector.select.addEventListener('change', this.setPriceTotalAndQuantity);


  }

  @boundMethod
  onChangeProductVariant (evt) {
    this.currentProductVariantCode = this.getSelectedProductVariantCode();
  }

  parsePrice(priceString) {
    let value = null;
    let currency = null;
    let format_detected = null;

    // Remove any non-numeric characters except for decimal separator and currency symbol
    let numericString = priceString.replace(/[^0-9.,€$]/g, '');

    // Check if the currency symbol appears at the beginning or end of the string
    if (numericString.startsWith('€') || numericString.endsWith('€')) {
      currency = '€';
    } else if (numericString.startsWith('$') || numericString.endsWith('$')) {
      currency = '$';
    }
    numericString = numericString.replace('$', '').replace('€', '');

    // Determine the format_detected based on the presence of comma or period as decimal separator
    if (numericString.includes(',')) {
      format_detected = 'fr';
    } else if (numericString.includes('.')) {
      format_detected = 'en';
    }

    // Parse the numeric value using the detected format
    value = parseFloat(numericString.replace(',', '.'));

    return {
      value,
      currency,
      format_detected
    };
  }


  @boundMethod
  setPriceTotalAndQuantity (evt) {
    if (!this.priceElement) {
      return;
    }
    const price = this.parsePrice(this.currentProducVariant.price);
    const originalPrice = this.parsePrice(this.currentProducVariant.originalPrice);
    const quantity = 1;
    const totalPrice = (price.value * quantity).toFixed(2).replace('.', ',');
    const totalOriginalPrice = (originalPrice.value * quantity).toFixed(2).replace('.', ',');

    if (price.format_detected === 'en') {
      this.priceElement.innerHTML = `€${price.value.toFixed(2)}`;
      this.originalPriceElement.innerHTML = `<del>€${originalPrice.value.toFixed(2)}</del>`;
    } else {
      this.priceElement.innerHTML = `${price.value.toFixed(2).replace('.', ',')} €`;
      this.originalPriceElement.innerHTML = `<del>${originalPrice.value.toFixed(2).replace('.', ',')} €</del>`;
    }
    if (price.value === originalPrice.value) {
      this.originalPriceElement.style.display = `none`;
      return;
    }

    this.originalPriceElement.style.display = `inline`;
  }

  get currentProducVariant () {
    if (
      null === this.dataProductVariant[this.currentProductVariantCode]
    ) {
      return null;
    }
    return this.dataProductVariant[this.currentProductVariantCode];
  }

  set currentProductVariantCode (code) {
    this._currentProductVariantCode = code;
    this.setAttributeForProductVariantCode();
  }

  get currentProductVariantCode () {
    return this._currentProductVariantCode;
  }

  @boundMethod
  setAttributeForProductVariantCode () {
    if (
      null === this.currentProducVariant ||
      null === this.priceElement
    ) {
      return;
    }

    this.priceElement.innerText = this.currentProducVariant.price;

    try {
      this.inputQuantity.value = this.currentProducVariant.min;
      this.inputQuantity.setAttribute('min', this.currentProducVariant.min);
      this.inputQuantity.setAttribute('max', this.currentProducVariant.max);
      this.inputQuantity.setAttribute('step', this.currentProducVariant.step);
    } catch (execption) {
      this.inputQuantity.value = 1;
      this.inputQuantity.setAttribute('min', 1);
      this.inputQuantity.removeAttribute('max');
      this.inputQuantity.removeAttribute('step');
    }

    if (this.inputQuantity.getAttribute('max') === '0') {
      this.formAddToCart.querySelector(`.product-quantity`).classList.add('hide');
      this.formAddToCart.classList.add('maximum-reached');
      this.formAddToCart.querySelector(`.product-quantity-maximum-reached`).classList.add('show');
      this.formAddToCart.querySelector(`[type='submit']`).setAttribute('disabled', 'true');
    } else {
      this.formAddToCart.querySelector(`.product-quantity`).classList.remove('hide');
      this.formAddToCart.querySelector(`.product-quantity-maximum-reached`).classList.remove('show');
      this.formAddToCart.classList.remove('maximum-reached');
      this.formAddToCart.querySelector(`[type='submit']`).removeAttribute('disabled');

    }
    this.setPriceTotalAndQuantity();

  }

  connect () {
    this.dataProductVariant = JSON.parse(this.element.dataset.productVariants);
    this.formAddToCart = this.element.querySelector(`input[type=hidden]`).form;
    this.priceElement = this.formAddToCart.querySelector('#product-price');
    this.originalPriceElement = this.formAddToCart.querySelector('#product-original-price');
    this.addToCart = new AddToCart(this.formAddToCart);
    this.initInputQuantity();
    this.selectProductVariant = this.formAddToCart.querySelector(`[name='sylius_add_to_cart[cartItem][variant]']`);

    if (this.selectProductVariant!=null) {
      this.selectProductVariant.addEventListener('change', this.onChangeProductVariant);
      this.currentProductVariantCode = this.getSelectedProductVariantCode();
    } else {
      this.currentProductVariantCode = Object.keys(this.dataProductVariant)[0];
    }
  }

  disconnect () {
    this.addToCart && this.addToCart.disconnect();
    this.selectProductVariant && this.selectProductVariant.removeEventListener('change', this.onChangeProductVariant);
    this.inputQuantity && this.inputQuantity.removeEventListener('change', this.setPriceTotalAndQuantity);
    this.quantitySelector && this.quantitySelector.select.removeEventListener('change', this.setPriceTotalAndQuantity);
    this.quantitySelector && this.quantitySelector.destructor();
  }

}
