import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import {boundMethod} from 'autobind-decorator';

export default class extends Controller {

  connect() {
    this.popup = document.querySelector('.popupAgeVerification');

    if (this.popup && !document.cookie.includes('AgeVerification=true')) {
      gsap.to(this.element, {
        opacity: 1,
        pointerEvents: 'auto',
        duration: 0.3,
      });
      this.popup.addEventListener('click', this.onClick);
    } else {
      this.element.remove();
    }
  }

  @boundMethod
  onClose(event) {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 days in milliseconds
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `AgeVerification=true${expires};path=/`;
    gsap.to(this.element, {
      opacity: 0,
      pointerEvents: 'none',
      duration: 0.3, 

    }).then( ()=>{
      this.element.remove();
    });
  }
}
