import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { startStimulusApp } from '@symfony/stimulus-bridge';
import './flash-message/flash-message_handler.js';
import './links-active/links-active_processor.js';

gsap.registerPlugin(ScrollTrigger);

export const app = startStimulusApp(require.context(
  '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
  true,
  /\.(j|t)sx?$/,
));
app.debug = false;
window.Stimulus = app;
