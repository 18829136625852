/* eslint camelcase: "off", import/no-named-as-default: "off", import/no-named-as-default-member: "off" */
/* eslint import/extensions: "off", import/no-unresolved: "off"  */
import { app } from 'shop/assets/js/bootstrap';

import './styles/index.scss';


import './img/rose.png';
import './img/rouge.png';
import './img/blanc.png';

// => Importer manuellement les controllers du plugin wine
import MillesimeSelectorController from './controllers/millesime-selector_controller';
app.register('vinium-sylius-wine-plugin--millesime-selector', MillesimeSelectorController);
