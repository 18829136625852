import {Controller} from '@hotwired/stimulus';
import gsap from "gsap";
import {useDispatch, useWindowResize} from 'stimulus-use'

export default class extends Controller {


  static targets = ['popup1', 'popup2'];
  static popup1;
  static popup2;

  connect() {
    useDispatch(this);
    useWindowResize(this);
  }

  initialize() {
    this.body = document.body;
    this.popup1 = this.popup1Target;

    if (this.hasPopup2Target) {
      this.popup2 = this.popup2Target;
    }
  }

  windowResize({width, height, event}) {
    if (width > 767) {
      this.topOffset = 180;
    } else {
      this.topOffset = 100;
    }
  }

  openPopup(e) {
    let popups = document.querySelectorAll(".popup");
    popups.forEach(popup => {
      popup.classList.remove('open');
    });

    let toScroll;
    if (e.currentTarget.dataset.popup == 1) {
      this.popup1.classList.add('open');
      toScroll = this.popup1;
    }
    if (e.currentTarget.dataset.popup == 2) {
      this.popup2.classList.add('open');
      toScroll = this.popup1;
    }

    const targetElement = toScroll

    if (targetElement) {
      window.scrollTo({
        top: targetElement.getBoundingClientRect().top + window.scrollY - this.topOffset,
        behavior: 'smooth',
      });
    }
  }

  closePopup(e) {
    let popups = document.querySelectorAll(".popup");
    popups.forEach(popup => {
      popup.classList.remove('open');
    });
  }

  disconnect() {

    super.disconnect();

  }
}
