import { Controller } from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';
import axios from 'axios'
import serialize from 'form-serialize'
import {CART_EVENTS} from "../../events/cart";

// Global axios settings
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
axios.defaults.headers.post.accept = 'application/json, text/javascript, */*; q=0.01'
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
export default class extends Controller {
  connect () {
    this.element.addEventListener('submit', this.onSubmit)

  }

  @boundMethod
  performRequest () {
    const request = axios.post(this.element.getAttribute("action"), serialize(this.element))
    request.then(() => {
      this.element.classList.remove('loading');
      this.element.classList.add('success');
      this.element.querySelector(".successAnimation").classList.add('animated');
      setTimeout(()=>{
        this.element.classList.remove('success');
        this.element.querySelector(".successAnimation").classList.remove('animated');
      },
        2500)
      setTimeout(()=>{
        document.dispatchEvent(new CustomEvent(CART_EVENTS.update,{detail:{open:true}}));
        document.dispatchEvent(new CustomEvent(CART_EVENTS.additem_completed,{}));
      },500)


    })
    request.catch(
      (error) => {

        let validationMessage = ''
        if (error.response.data.message) {
          validationMessage += error.response.data.message
        } else {
          Object.entries(error.response.data).forEach(([, message]) => {
            validationMessage += message.errors
          })
        }

        this.element.classList.remove('loading');
        this.displayError(validationMessage)
      },
    )
  }

  @boundMethod
  onSubmit (evt) {
    evt.preventDefault()

    this.element.classList.add('loading');

    const formData = new FormData(this.element);
    const validationRequest = axios.post(`${this.element.dataset.validationUrl}&productVariantCode=${formData.get('sylius_add_to_cart[cartItem][variant]')}`, serialize(this.element))
    validationRequest
      .then((response) => {
        if (typeof response.data.confirmationModal === 'undefined') {
          this.performRequest()
        }

      })
      .catch( (error)=>{
        this.element.classList.remove('loading');
        this.displayError(error.response.data.message);

      })
  }
  @boundMethod
  displayError (message) {
    const template = document.getElementById("templatePopInAddToCartError");
    const popinError = template.content.cloneNode(true);
    popinError.firstElementChild.innerHTML = popinError.firstElementChild.innerHTML.replace('%content%',message)
    document.body.appendChild(popinError);
  }


  disconnect () {
    this.element.removeEventListener('submit', this.onSubmit)
  }

}
