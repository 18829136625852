import { Controller } from "@hotwired/stimulus";
import { boundMethod } from "autobind-decorator";

export default class extends Controller {
  connect() {
    this.listDesktop = this.element.querySelector(".wine-header-card.desktop");

    this.listDesktop.querySelectorAll(".ui-element.wine-link").forEach(el => {
      el.addEventListener("mouseover", this.onMouseOver);
      el.addEventListener("mouseout", this.onMouseOut);
    });
  }

  @boundMethod
  onMouseOver(event) {
    const wine = event.currentTarget.dataset.wine;
    this.hoveredWine = this.listDesktop.querySelector(`.ui-element.wine-bottle[data-wine="${wine}"]`);
    if (this.hoveredWine) {
      this.hoveredWine.classList.add("scale-105");
    }
  }

  @boundMethod
  onMouseOut() {
    if (this.hoveredWine) {
      this.hoveredWine.classList.remove("scale-105");
    }
  }

  disconnect() {
    this.listDesktop.querySelectorAll(".ui-element.wine-link").forEach(el => {
      el.removeEventListener("mouseover", this.onMouseOver);
      el.removeEventListener("mouseout", this.onMouseOut);
    });
  }
}
