import { Controller } from "@hotwired/stimulus";
import { boundMethod } from "autobind-decorator";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends Controller {
  static values = {
    duration: Number,
    stagger: Number,
    delay: Number,
    threshold: Number,
  };

  connect() {
    this.items = this.element.querySelectorAll(":scope > *");

    this.initAnimation();
    gsap.registerPlugin(ScrollTrigger);

    this.triggers = ScrollTrigger.batch(this.items, {
      once: true,
      start: `top ${this.thresholdValue || 75}%`,
      // markers: true,
      onEnter: (elements, triggers) => {
        gsap.to(elements, {
          opacity: 1,
          duration: this.durationValue || 0.5,
          stagger: this.staggerValue || 0.1,
          delay: this.delayValue || 0,
          ease: "power2.out",
        });
      },
    });
  }

  @boundMethod
  initAnimation() {
    gsap.set(this.items, {
      opacity: 0,
    });
  }

  disconnect() {
    this.triggers.forEach((st) => {
      st.kill();
      st.refresh();
    });
  }
}
