import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = [ "tooltip"]
  showEvents = ['mouseenter', 'focus'];
  hideEvents = ['mouseleave', 'blur'];

  initialize() {
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.showEvents.forEach((event) => {
      this.element.addEventListener(event, this.show);
    });

    this.hideEvents.forEach((event) => {
      this.element.addEventListener(event, this.hide);
    });
  }

  connect() {
    this.popperInstance = createPopper(this.element, this.tooltipTarget, {
      placement: 'top',
      resize: true,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  show() {
    this.tooltipTarget.setAttribute('data-show', '');
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }));
    this.popperInstance.update();
  }

  hide() {
    this.tooltipTarget.removeAttribute('data-show');
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }));
  }

  disconnect() {
    this.showEvents.forEach((event) => {
      this.element.removeEventListener(event, this.show);
    });

    this.hideEvents.forEach((event) => {
      this.element.removeEventListener(event, this.hide);
    });
  }
}
