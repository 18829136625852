import { Controller } from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';

export default class extends Controller {
  connect() {
    this.formCart = document.querySelector('form[name="sylius_cart"]');

  }

  @boundMethod
  onChange(event) {
    if (!this.formCart) return;
    event.preventDefault();
    this.formCart.submit();

  }
}
