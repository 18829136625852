import { Controller } from "@hotwired/stimulus";
import { useWindowResize } from "stimulus-use";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends Controller {
  // static targets = ["width"];
  static values = {
    yFrom: Number,
    yTo: Number,
    triggerStart: String,
    triggerEnd: String,
    scrub: Number,
    mobile: Boolean,
  };
  connect() {
    gsap.registerPlugin(ScrollTrigger);
    useWindowResize(this);
    this.mobileValue = this.mobileValue || false;

    if (this.mobileValue) {
      gsap.set(this.element, {
        yPercent: this.yFromValue || 0,
      });
    }
  }

  windowResize({ width, height, event }) {
    // console.log(width);
    if (width > 767) {
      // console.log("desktop");
      gsap.to(this.element, {
        yPercent: this.yToValue || 0,
        ease: "none",
        force3D: true,
  
        scrollTrigger: {
          trigger: this.element,
          start: `top ${this.triggerStartValue || "100%"}`,
          end: `bottom ${this.triggerEndValue || "0%"}`,
          // markers: true,
          scrub: this.scrubValue || true,
        },
      });
    } else {
      if (this.mobileValue) {
        gsap.to(this.element, {
          yPercent: this.yToValue || 0,
          ease: "none",
          force3D: true,
    
          scrollTrigger: {
            trigger: this.element,
            start: `top ${this.triggerStartValue || "100%"}`,
            end: `bottom ${this.triggerEndValue || "0%"}`,
            // markers: true,
            scrub: this.scrubValue || true,
          },
        });
      } else {
        // console.log("small tablet & mobile");
        ScrollTrigger.getAll().forEach((trigger) => {
          if (trigger.trigger === this.element) {
            trigger.kill();
            gsap.to(this.element, {
              yPercent: 0,
            });
          }
        });
      }
    }
  }

  disconnect() {
    ScrollTrigger.getAll().forEach((trigger) => {
      if (trigger.trigger === this.element) {
        trigger.kill();
        gsap.to(this.element, {
          yPercent: 0,
        });
      }
    });
  }
}
