import { Controller } from '@hotwired/stimulus';
import FormatPrice from 'shop/assets/js/formatPrice';


export default class OfferSegmentProduct extends Controller {
  initialize() {
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.onSegmentUpdateRemaining = this.onSegmentUpdateRemaining.bind(this);

    this.valueElem = this.element.querySelector(`.valueElem`);
    this.input = this.element.querySelector(`input[type=number],input[type=range]`);
    this.input.addEventListener('input', this.onQuantityChange);
    this.maximumAttribute = this.input.getAttribute('max');
    document.body.addEventListener(
      `${this.element.dataset.segmentId}:segmentRemainingChange`,
      this.onSegmentUpdateRemaining
    );
    setTimeout(() => {
      this.onQuantityChange();

    }, 0);

  }

  connect() {

  }

  disconnect() {
    document.body.removeEventListener(
      `${this.element.dataset.segmentId}:segmentRemainingChange`,
      this.onSegmentUpdateRemaining
    );
    this.input.removeEventListener('change', this.onQuantityChange);
    this.input.removeEventListener('input', this.onQuantityChange);

  }

  onQuantityChange() {
    this.updateQuantity();
    this.updateTotalPrice();
    this.dispatchQuantityChange();


  }

  dispatchQuantityChange() {
    /**
     * on dispatch le changement de quantité catché par l'offre
     * l'offre dispatch ensuite le nombre de bouteille restant
     *
     */
    document.body.dispatchEvent(
      new CustomEvent(
        `offer:${this.element.dataset.offerId}:productQuantityChange`,
        { 'detail': this }
      )
    );
  }

  onSegmentUpdateRemaining(event) {
    this.updateMaximumWithSegmentRemaining(event.detail.remaining);

  }

  updateMaximumWithSegmentRemaining(remainingSegment) {
    this.input.removeAttribute('disabled');
    const remainingSegmentByOfferUnit = Math.max(parseInt(remainingSegment/this.offerUnit),0);

    if (this.remaining <= remainingSegmentByOfferUnit) {
      this.updateMaximumAttribute(this.maximumAttribute);
    } else {
      const nextMax = parseInt(this.quantity) + remainingSegmentByOfferUnit;
      if (nextMax == 0) {
        this.input.setAttribute('disabled', true);
      } else {
        this.updateMaximumAttribute(nextMax);
      }
    }

  }

  updateMaximumAttribute(newMaximum) {

    this.input.setAttribute('max', newMaximum);
    this.maximumElt.innerHTML = newMaximum;
    this.updateValue();
  }

  updateTotalPrice() {
    this.priceTotalElt.innerHTML = FormatPrice(this.quantity * this.unitPrice);

  }

  updateQuantity() {
    this.quantityElt.forEach(elt => elt.innerHTML = this.quantity);
    this.updateValue();
  }

  updateValue() {
    this.valueElem.innerHTML = this.quantity > 0 ? this.quantity : '';
    this.valueElem.style.left = ((this.quantity / parseInt(this.maximumElt.innerHTML,10)) * 100) + '%';
    this.input.style.backgroundSize = ((this.quantity / parseFloat(this.maximumElt.innerHTML)) * 100) + '% 100%';
  }

  get quantity() {
    return this.input.value;
  }

  get offerUnit() {
    return this.element.dataset.variantOfferUnit;
  }

  get unitPrice() {
    return this.element.dataset.variantUnitPrice;
  }

  get remaining() {
    return this.maximumAttribute - this.quantity;
  }

  get quantityElt() {
    return this.element.querySelectorAll('[data-variant-quantity]');
  }

  get maximumElt() {
    return this.element.querySelector('[data-variant-max]');
  }

  get priceTotalElt() {
    return this.element.querySelector('[data-variant-total-price]');
  }

}
