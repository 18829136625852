import { Controller } from '@hotwired/stimulus';
import { useWindowResize } from "stimulus-use";
import { boundMethod } from 'autobind-decorator';
import gsap from "gsap";

export default class extends Controller {
  static values = {
    pageCode: String,
  };

  connect() {
    // VARS
    this.isOpen = false;
    this.anchors = [];
    this.anchorDrawerTrigger = this.element.querySelector('.anchors-drawer-trigger');
    this.anchorDrawerTriggerLabel = this.anchorDrawerTrigger.querySelector('.anchor-drawer-selected-label');
    this.anchorsContainer = this.element.querySelector('.anchors-container');

    // EVENTS
    this.anchorDrawerTrigger.addEventListener('click', this.handleClickOnTrigger);

    // INIT
    this.createMenu();
    useWindowResize(this);
  }

  disconnect() {
    this.anchorDrawerTrigger.removeEventListener('click', this.handleClickOnTrigger);
    this.anchorMenuItems.forEach((anchorMenuItem, index) => {
      anchorMenuItem.removeEventListener('click', this.handleClickOnAnchor);
    });
  }

  @boundMethod
  windowResize({ width, height }) {
    window.innerWidth > 767 ? this.open() : this.close();
  }

  @boundMethod
  createMenu() {
    this.pageElement = document.querySelector(`.page`);
    if (!this.pageElement) {
      return;
    }
    this.anchors = [...this.pageElement.querySelectorAll('.ui-element-anchor')];
    let lis = '';
    this.anchors.forEach((anchor, index) => {
      lis += `
        <div class="anchor-menu-item text-[16px] hover:text-accent transition-colors">
            <a href="#${anchor.id}" data-no-swup="" data-controller="monsieur-biz-sylius-editor-plugin--anchor-link" >
                ${anchor.innerHTML}
            </a>
        </div>
      `;
    });
    this.anchorsContainer.innerHTML = `${lis}`;

    this.anchorMenuItems = this.anchorsContainer.querySelectorAll('.anchor-menu-item > a');
    this.anchorMenuItems.forEach((anchorMenuItem, index) => {
      // Add first anchor label to anchorDrawerTrigger
      index === 0 ? this.anchorDrawerTriggerLabel.innerHTML = anchorMenuItem.innerHTML : null;
      // Handle click on anchor menu item
      anchorMenuItem.addEventListener('click', this.handleClickOnAnchor);
    });
  }

  @boundMethod
  handleClickOnTrigger(e) {
    this.isOpen ? this.close() : this.open();
  }

  @boundMethod
  handleClickOnAnchor(e) {
    if (window.innerWidth < 768) {
      this.close();

      let selectedAnchorLabel = e.currentTarget.innerHTML;
      let tl = gsap.timeline();

      tl.to(this.anchorDrawerTrigger, {
        opacity: 0,
        onComplete: () => {
          this.anchorDrawerTriggerLabel.innerHTML = selectedAnchorLabel;
        }
      });
      tl.to(this.anchorDrawerTrigger, {
        opacity: 1,
      });
    } else {
      this.open();
    }
  }


  @boundMethod
  open() {
    this.isOpen = true;
    this.element.classList.add("is-active");
    this.element.classList.remove("is-hidden");
    this.anchorsContainer.style.maxHeight = `${250}px`;
  }

  @boundMethod
  close() {
    this.isOpen = false;
    this.element.classList.remove("is-active");
    this.anchorsContainer.style.maxHeight = `${0}px`;
  }
}
