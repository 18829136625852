import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ["firstPassword", "secondPassword" ]

  check() {
    const password = this.firstPasswordTarget.value;
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(.{6,})$/m;
    const found = password.match(regex);
    if (found) {
      this.element.classList.add('check_password_valid');
      this.element.classList.contains('check_password_error') && this.element.classList.remove('check_password_error');
    } else {
      this.element.classList.contains('check_password_valid') && this.element.classList.remove('check_password_valid');
      this.element.classList.add('check_password_error');
    }

    this.compare()
  }

  compare() {
    if (this.secondPasswordTarget) {
      if (this.secondPasswordTarget.value === this.firstPasswordTarget.value) {
        this.element.classList.add('check_password_diff_valid');
        this.element.classList.contains('check_password_diff_error') && this.element.classList.remove('check_password_diff_error');
      } else {
        this.element.classList.contains('check_password_diff_valid') && this.element.classList.remove('check_password_diff_valid');
        this.element.classList.add('check_password_diff_error');
      }
    }
  }
}
