import { Controller } from '@hotwired/stimulus';
import FormatPrice from 'shop/assets/js/formatPrice';

export default class OfferSegment extends Controller {
  initialize () {
    this.onOfferUpdateRemaining = this.onOfferUpdateRemaining.bind(this);
    this.maximumAttribute = this.element.dataset.segmentMax;
    this.minimumAttribute = this.element.dataset.segmentMin;
    document.body.addEventListener(`offer:${this.element.dataset.offerId}:remainingQuantityChange`,
      this.onOfferUpdateRemaining);
    this.products = [];
    setTimeout(() => {
      this.products = [...this.element.querySelectorAll(`[data-controller="black-sylius-offer-plugin--offer-segment-product"]`)].map(elt => {
        return this.application.getControllerForElementAndIdentifier(elt, 'black-sylius-offer-plugin--offer-segment-product');
      });

      this.maximum = this.maximumAttribute;
    }, 0);

    this.svgTemplate = `<svg width="7" height="27" viewBox="0 0 97.412 380.721"> <path  d="M206.836,94.524V0H173.862V94.524c0,17.132-32.207,37.929-32.207,62.346V364.106c0,6.229,4.166,16.615,13.5,16.615h70.4c9.342,0,13.513-10.387,13.513-16.615V156.87C239.066,132.453,206.836,111.667,206.836,94.524Z" transform="translate(-141.655)" fill="white"/> </svg> `;

  }

  connect () {

  }

  disconnect () {
    document.body.removeEventListener(`offer:${this.element.dataset.offerId}:remainingQuantityChange`,
      this.onOfferUpdateRemaining);

  }

  onOfferUpdateRemaining (event) {
    this.updateMaximumWithOfferRemaining(event.detail.remaining);
    this.updateQuantity();
    this.updateTotalPrice();
    this.dispatchRemainingChange();

  }

  updateMaximumWithOfferRemaining (remainingOffer) {
    if (this.remaining < remainingOffer) {
      this.maximum = this.maximumAttribute;
    } else {
      const nextMax = parseInt(this.quantity) + parseInt(remainingOffer);
      this.maximum = nextMax;
    }

  }

  dispatchRemainingChange () {
    document.body.dispatchEvent(
      new CustomEvent(`${this.element.dataset.segmentId}:segmentRemainingChange`, { 'detail': this }));

  }

  updateTotalPrice () {
    this.priceTotalElt.innerHTML = FormatPrice(this.totalPrice);

  }

  updateQuantity() {
    this.quantityElt.innerHTML = this.quantity;
  }


  get id () {
    return this.element.dataset.segmentId;

  }

  get remaining () {
    return this.maximum - this.quantity;

  }

  get totalPrice () {
    return this.products.reduce((accumulateur, product) => {
      return accumulateur + parseInt(product.quantity * product.unitPrice);
    }, 0);

  }

  get quantity () {
    return this.products.reduce((accumulateur, product) => {
      return accumulateur + parseInt(product.quantity * product.offerUnit);
    }, 0);

  }

  get maximum () {
    return this._maximum;

  }

  set maximum (maximum) {
    this._maximum = Math.max(maximum,0);
    this.maximumElt.innerHTML = this._maximum;
    this.remainingElt.innerHTML = this.remaining;
    this.makeStatusQty();

  }

  makeStatusQty () {
    this.quantityStatusElt.innerHTML = '';
    let icos = '';
    for (let i = 0; i < this.maximum; i++) {
      icos+=this.svgTemplate;
    }
    this.quantityStatusElt.innerHTML = icos;
    let svgs= this.quantityStatusElt.querySelectorAll("svg");

    for (let i = 0; i < this.quantity; i++) {
      svgs[i] && svgs[i].classList.add("fullfill")
    }
    this.quantityStatusElt.innerHTML +=`<span class="yo">${this.quantity}/${this.maximum}</span>`;


  }

  get quantityStatusElt () {
    return this.element.querySelector('.offer_segment_status_qty');

  }

  get quantityElt () {
    return this.element.querySelector('[data-segment-total-quantity]');

  }

  get priceTotalElt () {
    return this.element.querySelector('[data-segment-total-price]');

  }

  get remainingElt () {
    return this.element.querySelector('[data-segment-remaining-quantity]');

  }

  get maximumElt () {
    return this.element.querySelector('[data-segment-maximum]');

  }

  get name () {
    return this.element.querySelector('.offer_segment_name').textContent;
  }

}
