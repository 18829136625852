import axios from 'axios';
import serialize from 'form-serialize';
import { CART_EVENTS } from './../events/cart';
// Global axios settings
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
axios.defaults.headers.post.accept = 'application/json, text/javascript, */*; q=0.01';
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';

export default class {
  constructor (elt) {
    this.onSubmit = this.onSubmit.bind(this);
    this.performRequest = this.performRequest.bind(this);
    this.element = elt;
    this.langue = window.location.pathname.split('/')[1];
    this.url_minipanier = `/${this.langue || 'fr_FR'}/minipanier/`;
    this.url = this.element.getAttribute('action');
    this.validationElement = this.element.querySelector('#sylius-cart-validation-error');
    this.element.addEventListener('submit', this.onSubmit);
  }

  disconnect () {
    this.element.removeEventListener('submit', this.onSubmit);
  }

  performRequest () {
    const request = axios.post(this.url, serialize(this.element));
    request.then(() => {
      this.validationElement.classList.add('d-none');
      this.element.classList.remove('loading');
      document.dispatchEvent(new CustomEvent(CART_EVENTS.update,{detail:{open:true}}));
    })

    request.catch(
      (error) => {
        this.validationElement.classList.remove('hidden')

        let validationMessage = ''
        if (error.response.data.message) {
          validationMessage += error.response.data.message
        } else {
          Object.entries(error.response.data).forEach(([, message]) => {
            validationMessage += message.errors
          })
        }

        this.validationElement.innerHTML = validationMessage
        this.element.classList.remove('loading')
      },
    )
  }

  onSubmit (evt) {
    evt.preventDefault();
    this.element.classList.add('loading');

    const validationRequest = axios.post(this.element.dataset.validationUrl, serialize(this.element));

    validationRequest.then((response) => {
      if (typeof response.data.confirmationModal === 'undefined') {
        this.performRequest();
        return;
      }
      this.element.classList.remove('loading');
      alert('Erreur');
    })
  }
}

