import { Controller } from "@hotwired/stimulus";
import {boundMethod} from "autobind-decorator";
import gsap from 'gsap';

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.popup = document.querySelector("#productModal")
    this.popupContent = this.popup.querySelector(".productModal_content")
    this.popupTheContent = this.popup.querySelector(".productModal_content .the_content")
    gsap.set(this.popupContent, {opacity: 0});
    document.addEventListener('swup:content:replace', this.hide);
    if( this.element.closest("#productModal")) {
      this.popup.addEventListener('click', this.clickOutside);
    }
  }

  @boundMethod
  clickOutside(event) {
    if( event.target.closest(".productModal_wrap") && this.popup.classList.contains('modal-open') === true) {
      return false;
    }
    this.hide();
  }

  @boundMethod
  trigger(event) {
    event.preventDefault();
    event.stopPropagation();
   if(
     event.target === this.popup ||
     event.target.closest("#productModal")
   ) {
      return this.hide();
    }
    if(this.popup.classList.contains('hidden')){
      this.load();
      this.show();
    }
  }

  @boundMethod
  show() {
    this.popup.classList.remove('hidden')
    this.popup.classList.add('modal-open')
  }

  @boundMethod
  hide() {
    this.anim('hide');
    this.popup.classList.remove('modal-open')
    this.popup.classList.add('hidden')
    this.popupTheContent.innerHTML = "";
  }

  load() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        this.popupTheContent.innerHTML = html
        setTimeout(() => this.anim('show'), 200);
      })
  }

  anim(statut = 'show'){
    if(statut !== 'show'){
      gsap.to(this.popupContent, {opacity: 0, duration: 0.3, ease: 'sine.out'});
      return;
    }
    gsap.to(this.popupContent, {opacity: 1, duration: 0.6, ease: 'sine.in'});
  }

  disconnect() {
    super.disconnect();
    if( this.element.closest("#productModal")) {
      this.popup.removeEventListener('click', this.clickOutside);
    }
  }
}
