import { Controller } from '@hotwired/stimulus';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupFadeTheme from '@swup/fade-theme';
import Swup from 'swup';
import { boundMethod } from 'autobind-decorator';
import gsap from "gsap";

export default class AppSwup extends Controller {

  initialize() {
    this.countPagesView = 1;
    if (this.skipAnimation){
      gsap.globalTimeline.timeScale(100);
      document.documentElement.style.setProperty('--lazy-image-opacity-time', "0s");
      setTimeout( () =>{
        document.documentElement.style.setProperty('--lazy-image-opacity-time', "0.8s");
        gsap.globalTimeline.timeScale(1);
      },1000)
    }
  }

  connect() {
    window.swup = new Swup({ animateHistoryBrowsing: false, containers: ['#swup'], cache: false });
    window.swup.use(new SwupScriptsPlugin({ head: false, body: true }));
    window.swup.use(new SwupBodyClassPlugin());
    window.swup.use(new SwupFadeTheme());

    document.addEventListener("swup:connect", this._onConnect);
    document.addEventListener("swup:content:replace", this.onContentReplaced);
    window.appSwup = this;
  }

  get isFirstPage(){
    return ( this.countPagesView === 1 )
  }

  get skipAnimation(){
    return (window.innerWidth < 800 && this.isFirstPage )
  }

  @boundMethod
  _onConnect(event) {
    this.handleActiveLink();
  }

  @boundMethod
  _onContentReplaced() {
    this.handleActiveLink();
  }

  @boundMethod
  handleActiveLink() {
    document
      .querySelectorAll(
        `[href],[selected-regex-href],[data-selected-regex-href]`
      )
      .forEach((elt) => {
        elt.classList.remove("selected");
      });

    document
      .querySelectorAll(
        `[href='${window.location.pathname}'],[selected-href='${window.location.pathname}']`
      )
      .forEach((elt) => {
        elt.classList.add("selected");
      });

    document.querySelectorAll(`[data-selected-regex-href]`).forEach((elt) => {
      if (window.location.pathname.match(elt.dataset.selectedRegexHref)) {
        elt.classList.add("selected");
      }
    });
  }


  @boundMethod
  onHistoryBack(event) {
    if (history) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return window.history.back();
    }
  }
}
