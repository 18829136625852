import { Controller } from "@hotwired/stimulus";
import { boundMethod } from "autobind-decorator";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends Controller {
  static values = {
    scale: Number,
  };
  connect() {
    gsap.registerPlugin(ScrollTrigger);
    // console.log("parallax-scale-image_controller connected");

    // this.element.style.objectPosition = "50% 50%";
    gsap.to(this.element, {
      scale: this.scaleValue || 1.2,
      rotation: 0.01, // Firefox lag fix
      ease: "none",
      scrollTrigger: {
        toggleActions: "play pause reverse pause",
        start: "top top",
        end: "bottom 50%",
        // markers: true,
        scrub: 2,
      },
    });
  }

  @boundMethod
  isFirefox() {
    // console.log(navigator.userAgent.toLowerCase().indexOf("firefox") > -1);
    return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  }
}
