import { IntersectionController} from "stimulus-use";
import {boundMethod} from 'autobind-decorator';
import gsap from "gsap";

export default class extends IntersectionController {
  connect() {
    this.body = document.body;
    this.element.addEventListener("click",this.onClick);
    this.menuItems = document.body.querySelectorAll(".menu-smartphone .menu-item");
    this.menuSmartphone = document.body.querySelector(".menu-smartphone");
    this.animationItems = this.menuSmartphone.querySelectorAll(".menu-item, .icon.menu-separator, .instagram, .facebook, #sylius-locale-selector-mobile");

    // console.log(this.animationItems);
    
    this.menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", this.onClickItem);
    });
    document.addEventListener('swup:content:replace', this.onLocationChange);

    this.animationItems.forEach((item, index) => {
      item.style.opacity = 0;
      item.style.pointerEvents = "none";
    });
  }

  @boundMethod
  onLocationChange(){
    this.body.classList.remove('menu-open');
  }

  @boundMethod
  appear() {
    this.element.dispatchEvent(new CustomEvent("appear:el"));
    this.unobserve();
  }


  @boundMethod
  onClick(evt){
    evt.stopImmediatePropagation();
    this.element.classList.toggle('trigger-open', !this.body.classList.contains("menu-open"));
    this.element.classList.toggle('trigger-close', this.body.classList.contains("menu-open"));
    this.body.classList.toggle("menu-open")

    if (this.body.classList.contains("menu-open")) {
      this.animationItems.forEach((item, index) => {
        gsap.to(item, {opacity: 1, pointerEvents: "auto", duration: 0.6, delay: index * 0.1});
      });
    } else {
      this.animationItems.forEach((item, index) => {
        gsap.to(item, {duration: 0.6, opacity: 0, pointerEvents: "none"});
      });
    }
  }
  @boundMethod
  onClickItem(evt){
    this.body.classList.remove("menu-open");
    this.animationItems.forEach((item, index) => {
      gsap.set(item, {opacity: 0, pointerEvents: "none"});
    });
  }
  disconnect() {
    this.menuItems.forEach((menuItem) => {
      menuItem.removeEventListener("click", this.onClickItem);
    });
  }
}
