import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default class extends Controller {
  static targets = []

  initialize(){

    gsap.set(
      [
       this.element
      ],
      {
        opacity:0,
        x:100
      }
    )

  }

  connect() {

    gsap.to(
      this.element,
      {
        delay:.5,
        x:0,
        ease:"expo.out",
        duration:1.3,

      }
    );

    gsap.to(
      this.element,
      {
        delay:.5,
        opacity:1,
        ease:"expo.in",
        duration:1,

      }
    );
  }

  disconnect() {

  }
}
