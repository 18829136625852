import {boundMethod} from "autobind-decorator";

class LinksActiveProcessor  {
  constructor() {
    document.addEventListener('swup:page:view', this.update);
    this.update();
  }

  @boundMethod
  async update(event) {

    let elts = document.querySelectorAll(`[href],[data-href]`);
    elts.forEach((elt) => {
      this.disableActiveLink(elt);
    });

    elts = document.querySelectorAll(`[href='${window.location.pathname}'],[data-href='${window.location.pathname}']`);
    elts.forEach((elt) => {
      this.enableActiveLink(elt);
    });
  }

  disableActiveLink(elt) {
    elt.classList.remove("selected");

  }

  enableActiveLink(elt){
    elt.classList.add("selected");

  }


}
export default new LinksActiveProcessor();
