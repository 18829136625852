import { Controller } from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';
import { CART_EVENTS } from './../../events/cart.js';
import {FLASHMESSAGE_EVENTS} from "../../events/flash-message.js";

export default class MiniCart extends Controller {
  static targets = ["content" ,"button", "itemsCount" ]
  connect() {
    document.addEventListener(CART_EVENTS.update,this.update)
    document.addEventListener('swup:page:view', this.update);
  }

  @boundMethod
  async update(event) {
    const langue = window.location.pathname.split('/')[1];
    const queryString = `/${langue || 'fr_FR'}/minipanier/`;
    const response = await fetch(queryString, {
      method: 'GET',
    });

    if(!response.ok){
      return;
    }

    const data = await response.json();
    this.contentTarget.innerHTML = data.result.content;
    this.itemsCountTarget.innerHTML = `(${data.result.nbitems})`;
    this.buttonTarget.classList.toggle('enablebadge', (data.result.nbitems > 0));
    if (event.detail?.open) {
      document.dispatchEvent(new CustomEvent(FLASHMESSAGE_EVENTS.update));
    }
  }

}
