import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export default class extends Controller {
  connect () {

    this.slider = new Swiper(
      this.element,
      {
        loop: true,
        autoHeight: false,
        preloadImages: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed:1000,
        effect: 'fade',
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        creativeEffect: {
          perspective:true,
          prev: {
            opacity:0,
            translate: ['0%', -1, -50],
          },
          next: {
            opacity:0,
            translate: ['0%', 1, 50],
          },
        },
        navigation: {
          nextEl: this.element.querySelector(".swiper-button-next"),
          prevEl: this.element.querySelector(".swiper-button-prev"),
        },
        lazy: {
          loadPrevNext: true,
        },
        pagination: false,
      },
    );

    window.beber = this.slider;

  }

  onClickBullet(event) {
    this.slider.slideToLoop(parseInt(event.target.dataset.index));
  }

  disconnect () {
    this.slider.destroy();
  }
}
