import {Controller} from '@hotwired/stimulus';
import gsap from 'gsap';
import {boundMethod} from 'autobind-decorator';

export default class extends Controller {

  connect() {
    this.element.addEventListener("click",this.onClick)
    gsap.to(this.element,{duration:.5,opacity:1})

  }
  disconnect() {
    super.disconnect();
    this.element.removeEventListener("click",this.onClick)
  }

  @boundMethod
  onClick(){
    gsap.to(this.element,{duration:.3,opacity:0}).then(()=>{
      this.element.remove();
    })

  }

}
