import { IntersectionController } from "stimulus-use";
import gsap from "gsap";
import { boundMethod } from "autobind-decorator";

export default class extends IntersectionController {
  static values = {
    x: Number,
    y: Number,
    mobileX: Number,
    mobileY: Number,
    duration: Number,
    delay: Number,
    waitForIntroVideo: Boolean || false,

    offsetX: Number,
    offsetY: Number,
  };

  options = {
    threshold: 0.2, // default
  };
  connect() {
    if( window.appSwup.skipAnimation ){
      return;
    }

    if (this.waitForIntroVideoValue) {
      window.addEventListener("intro-video:done", this.showElement);
    }

    // ANIMATION SETUP
    if (window.innerWidth < 768) {
      this.x = 0;
      this.y = 0;
      if (this.mobileXValue) {
        this.x = this.mobileXValue;
      } else if (this.mobileYValue) {
        this.y = this.mobileYValue;
      } else if (!this.mobileXValue && !this.mobileYValue) {
        this.x = this.xValue;
        this.y = this.yValue;
      }
      gsap.set(this.element, {
        opacity: 0,
        x: this.x,
        y: this.y,
      });
    } else {
      gsap.set(this.element, {
        opacity: 0,
        x: this.xValue || 0,
        y: this.yValue || 0,
      });
    }
  }
  disconnect() {
    if (this.waitForIntroVideoValue) {
      window.removeEventListener("intro-video:done", this.showElement);
    }
  }

  appear(entry) {
     this.unobserve();

     this.showElement();

  }

  @boundMethod
  showElement() {
    if( window.appSwup.skipAnimation ){
      return;
    }

    gsap.to(this.element, {
      opacity: 1,
      delay: this.delayValue || 0,
      duration: this.durationValue || 0.5,
      x: this.offsetXValue || 0,
      y: this.offsetYValue || 0,
      ease: "power2.out",
    });
  }
}
