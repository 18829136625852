import { Controller } from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';

export default class extends Controller {

  selectedElt = null;

  static targets = ["item"]

  connect() {
    this.form = this.element.parentElement;
    this.itemsObserver = new MutationObserver(this.mutateItems);
    this.itemTargets.forEach((item) => {
      this.itemsObserver.observe(item, { attributes: true });
    });
  }

  @boundMethod
  mutateItems(entries) {
    entries.forEach((mutation) => {
      if (mutation.attributeName === 'selected' && mutation.target.getAttribute(mutation.attributeName)) {
        this.selectedElt = mutation.target;
        this.replaceDatas();
      }
    });
  }

  get datas() {
    if(!this.selectedElt) return {};
     return  {
        'id': this.selectedElt.dataset.id,
        'firstName': this.selectedElt.dataset.firstName,
        'lastName': this.selectedElt.dataset.lastName,
        'company': this.selectedElt.dataset.company,
        'street': this.selectedElt.dataset.street,
        'countryCode': this.selectedElt.dataset.countryCode,
        'provinceName': this.selectedElt.dataset.provinceName,
        'city': this.selectedElt.dataset.city,
        'postcode': this.selectedElt.dataset.postcode,
        'phoneNumber': this.selectedElt.dataset.phoneNumber,
      }
  }

  findByName(name) {
    return this.form.querySelector(`[name*="[${this.parseKey(name)}]"]`);
  }

  parseKey(key) {
    return key.replace(/(_\w)/g, words => words[1].toUpperCase());
  }

  replaceDatas() {
      const { provinceCode, provinceName } = this.datas;
      const provinceContainer = (this.form.parentNode.querySelector('.province-container')) ? this.form.parentElement.querySelector('.province-container').get(0) : false;

      this.form.querySelectorAll('input:not([type="radio"]):not([type="checkbox"]), select').forEach((input) => {
        input.value = '';
      });

      Object.entries(this.datas).forEach(([property, value]) => {
        const field = this.findByName(property);

        if(field === null) return;

        if (property.indexOf('countryCode') !== -1) {
          field.value = value;

          const exists = setInterval(() => {
            const provinceCodeField = this.findByName('provinceCode');
            const provinceNameField = this.findByName('provinceName');
            if(!provinceContainer){
              clearInterval(exists);
              return;
            }

            if (!provinceContainer.hasAttribute('data-loading')) {
              if (provinceCodeField.length !== 0 && (provinceCode !== '' || provinceCode != undefined)) {
                provinceCodeField.value = provinceCode;

                clearInterval(exists);
              } else if (provinceNameField.length !== 0 && (provinceName !== '' || provinceName != undefined)) {
                provinceNameField.value = provinceName;

                clearInterval(exists);
              }
            }
          }, 100);
        } else if (field.type === "radio" || field.type ==="checkbox") {
          field.checked = false;
          field.filter(v => {
            if(v.attributes('value') === value) field.checked = true;
          });
        } else {
          field.value = value;
        }
      });
  }

  disconnect() {
    super.disconnect();
    this.itemsObserver.disconnect();
  }

}
