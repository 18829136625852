import {boundMethod} from "autobind-decorator";
import {FLASHMESSAGE_EVENTS} from "../events/flash-message.js";

class FlashMessageHandler {
  constructor() {
    document.addEventListener(FLASHMESSAGE_EVENTS.update,this.update);
    document.addEventListener('swup:page:view', this.update);

  }

  @boundMethod
  async update(event) {

    const langue = document.querySelector('html').dataset.lang;
    const url = `/${langue || 'fr_FR'}/flashes/`;
    const options = {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      }),

    };

    const response =  await fetch(url, options);

    if(!response.ok){
      return;
    }

    const text =  await response.text();
    if(text  && text.trim() != '' ) {

      document.body.append(
        this.createElementFromHTML(text)
      );
    }
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
  }


}
 export default new FlashMessageHandler();

