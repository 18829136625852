function * range (start = 0, end = undefined, step = 1) {
  if (arguments.length === 1) {end = start, start = 0;}

  [...arguments].forEach(arg => {
    if (typeof arg !== 'number') {throw new TypeError('Invalid argument');}
  });
  if (arguments.length === 0) {throw new TypeError('range requires at least 1 argument, got 0');}

  if (start > end) {
    return;
  }
  yield start;
  yield * range(parseInt(start + step), end, step);
}

export default class QuantitySelector {
  constructor (element) {
    this.input = element;
    this.onChangeAttribute = this.onChangeAttribute.bind(this);
    this.createSelect();
    this.observer = new MutationObserver(this.onChangeAttribute);
    this.observer.observe(this.input, { attributes: true });

  }

  onChangeAttribute () {
    const min = this.input.getAttribute('min') || 1;
    const max = Math.min(120, this.input.getAttribute('max') || 120);
    const step = this.input.getAttribute('step') || 1;
    this.select.innerHTML = '';
    [...range(parseInt(min), parseInt(max), parseInt(step))].forEach(elt => {
      let opt = document.createElement('option');
      opt.value = elt;
      opt.text = elt;
      this.select.add(opt, null);
    });
  }

  createSelect () {
    this.select = document.createElement('select');
    [...this.input.attributes].forEach(attr => { this.select.setAttribute(attr.nodeName, attr.nodeValue); });
    this.input.replaceWith(this.select);
  }

  destructor () {
    this.select = null;
    this.input = null;
    this.observer.disconnect();
  }
}
