import { Controller } from '@hotwired/stimulus';
import FormatPrice from 'shop/assets/js/formatPrice';
import axios from 'axios';
import serialize from 'form-serialize';

export default class OfferWithSegment extends Controller {
  initialize() {
    this.onProductUpdateQuantity = this.onProductUpdateQuantity.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.checkMinimum = this.checkMinimum.bind(this);
    // this.checkUnique = this.checkUnique.bind(this);
    document.body.addEventListener(
      `offer:${this.element.dataset.offerId}:productQuantityChange`,
      this.onProductUpdateQuantity,
    );
    this.segments = [];
    setTimeout(() => {
      this.segments = [...this.element.querySelectorAll('[data-controller="black-sylius-offer-plugin--offer-segment"]')].map(
        elt => this.application.getControllerForElementAndIdentifier(elt, 'black-sylius-offer-plugin--offer-segment'),
      );
      this.segments.forEach(segment => segment.offer = this);
    }, 0);

    this.minimum = this.element.dataset.offerMin;
    this.maximum = this.element.dataset.offerMax;
    this.form = this.element.querySelector('[name=\'app_shop_offer_allocation_type\']');
    this.form.addEventListener('submit', this.onSubmitForm, false);
    this.validationUrl = this.element.dataset.validationUrl;
  //  this.checkUnique();
  }

  // checkUnique () {
  //   const uniqueRequest = axios.get(`/offers/unique-checker?offerId=${this.element.dataset.offerId}`,);
  //
  //   uniqueRequest.then(response => {
  //     if(response.data.trim()!="") {
  //       var div = document.createElement('div');
  //       div.innerHTML = response.data.trim();
  //       document.body.appendChild(div.firstChild);
  //     }
  //   });
  // }

  onSubmitForm(event) {
    event.preventDefault();
    try {
      this.checkMinimum();

      const validationRequest = axios.post(this.validationUrl, serialize(event.target));
      validationRequest.then((response) => {
        if (typeof response.data.confirmationModal === 'undefined') {
          event.target.submit();
          return;
        }
        const $confirmationModal = $(response.data.confirmationModal);
        $confirmationModal.appendTo($('body'));
        $confirmationModal.find('[data-action="cancel"]').on('click', function () {
          $(this).closest('.confirmationPopin').remove();
        });
        $confirmationModal.find('[data-action="confirm"]').on('click', function () {
          $(this).closest('.confirmationPopin').remove();
          event.target.submit();
        });
      });
      validationRequest.catch((error) => {
      });
    } catch (error) {
      alert(error.message);
      event.preventDefault();
    } finally {

    }
  }

  checkMinimum() {
    let quantityOffer = 0;
    this.segments.forEach((segment) => {
      const quantitySegment = segment.products.reduce(
        (accumulator, product) => accumulator + parseInt(product.quantity),
        0,
      );
      if (quantitySegment < segment.minimumAttribute) {
        throw {
          message: `Quantité insuffisante pour ${segment.name}, ${segment.minimumAttribute} bouteille(s) au minimum`,
          segment,
        };
      }
      quantityOffer += quantitySegment;
    });

    if (this.minimum > quantityOffer) {
      throw {
        message: `Quantité insuffisante pour l'offre, ${this.minimum} bouteille(s) au minimum`,
      };
    }
    return true;
  }

  connect() {

  }

  disconnect() {
    this.form.removeEventListener('submit', this.onSubmitForm);
    document.body.removeEventListener(
      `offer:${this.element.dataset.offerId}:productQuantityChange`,
      this.onProductUpdateQuantity,
    );
  }

  onProductUpdateQuantity() {
    this.updateQuantity();
    this.updatePrice();
    this.dispatchRemainingChange();
  }

  dispatchRemainingChange() {
    document.body.dispatchEvent(new CustomEvent(
      `offer:${this.element.dataset.offerId}:remainingQuantityChange`,
      { detail: this },
    ));
  }

  updateQuantity() {
    this.quantityElt.innerHTML = this.quantity;
    this.element.querySelector('[data-offer-submit]').disabled = this.quantity === 0;
  }

  updatePrice() {
    this.priceElt.innerHTML = FormatPrice(this.price);
  }

  get remaining() {
    return this.maximum - this.quantity;
  }

  get quantityElt() {
    return this.element.querySelector('[data-total-offer-quantity]');
  }

  get quantity() {
    return this.segments.reduce((accumulateur, product) => accumulateur + parseInt(product.quantity), 0);
  }

  get price() {
    return this.segments.reduce((accumulateur, product) => accumulateur + parseInt(product.totalPrice), 0);
  }

  get priceElt() {
    return this.element.querySelector('[data-total-offer-price]');
  }
}
