import { Controller } from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';

export default class extends Controller {
  connect() {
    if (this.element.querySelectorAll('[data-variant-options]').length > 0) {
      this.handleProductOptionImages();
      this.handleProductOptionChange();
    } else if (this.element.querySelectorAll('[data-variant-code]').length > 0) {
      this.handleProductVariantImages();
      this.handleProductVariantChange();
    }
  }

  @boundMethod
  changeMainImage(newImageDiv) {
    const mainImageLink = document.querySelectorAll('a.fluid.image');
    const mainImage = document.querySelectorAll('a.fluid.image > img');

    const newImage = newImageDiv.querySelectorAll('img');
    const newImageLink = newImageDiv.querySelectorAll('a');

    if (newImage.length === 0 && newImageLink.length === 0) {
      document.querySelectorAll('div[data-product-image]').forEach((el) => {
        mainImage.setAttribute('src', el.getAttribute('data-product-image'));
      });
      document.querySelectorAll('div[data-product-link]').forEach((el) => {
        newImageLink.setAttribute('src', el.getAttribute('data-product-link'));
      });
      return;
    }

    mainImageLink.attr('href', newImageLink.attr('href'));
    mainImage.attr('src', newImage.attr('data-large-thumbnail'));
  }

  @boundMethod
  handleProductOptionImages() {
    let options = '';

    document.querySelectorAll('#sylius-product-adding-to-cart select').forEach((select) => {
      options += select.querySelector('option:selected').value;
    });

    const imagesWithOptions = [];
    const optionsArray = options.trim().split(' ');

    document.querySelectorAll(('[data-variant-options]').forEach((element) => {
      const imageOptions = element.getAttribute('data-variant-options');
      const imageHasOptions = optionsArray.every(option => imageOptions.indexOf(option) > -1);

      if (imageHasOptions) {
        imagesWithOptions.push(element.closest('div.variant-image-container'));
      }
    }));

    this.changeMainImage(imagesWithOptions.shift());
  }

  @boundMethod
  handleProductOptionChange() {
    document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach(el => el.addEventListener('change', this.handleProductOptionImages));
  }

  @boundMethod
  removeHandleProductOptionChange() {
    document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach(el => el.removeEventListener('change', this.handleProductOptionImages));
  }

  @boundMethod
  handleProductVariantImages(event) {
    const variantElement = (event.currentTarget) ? event.currentTarget : document.querySelector('[name="sylius_add_to_cart[cartItem][variant]"]');
    const variantCode = variantElement.value;
    const imagesWithVariantCode = [];

    document.querySelectorAll($(`[data-variant-code*="${variantCode}"]`)).forEach(element => {
      imagesWithVariantCode.push(element.closest('div.variant-image-container'));
    })
    this.changeMainImage(imagesWithVariantCode.shift());
  }

  @boundMethod
  handleProductVariantChange() {
    document.querySelectorAll('[name="sylius_add_to_cart[cartItem][variant]"]').forEach(el => el.addEventListener('change', this.handleProductVariantImages));
  }

  @boundMethod
  removeHandleProductVariantChange() {
    document.querySelectorAll('[name="sylius_add_to_cart[cartItem][variant]"]').forEach(el => el.removeEventListener('change', this.handleProductVariantImages));
  }

  disconnect() {
    if (this.element.querySelectorAll('[data-variant-options]').length > 0) {
      this.removeHandleProductOptionChange()
    } else if (this.element.querySelectorAll('[data-variant-code]').length > 0) {
      this.removeHandleProductVariantChange();
    }
  }
}
